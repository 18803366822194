import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Row, Container, Col } from "react-bootstrap";
import meter1 from "../assets/img/meter1.svg"
import meter2 from "../assets/img/meter2.svg"
import meter3 from "../assets/img/meter3.svg"
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="skills">
      <Container>
        <Row>
          <Col>
            <div className="skill-bx">
              <h2>Skills</h2>
              <p>
                • Programming Languages:{" "}
                <strong>
                  Python, React, JavaScript, HTML, CSS, C, Java, R, Stata, SQL,
                  PostgreSQL
                </strong>
                <br></br>• Databases: <strong>Firebase and MongoDB</strong>
                <br></br>• Machine Learning Algorithms:{" "}
                <strong>
                  Regression, Classification, Clustering, and KNN{" "}
                </strong>
                <br></br>• Tools:{" "}
                <strong>Git, GitHub, Figma, and Google Analytics</strong> <br></br>•
                Operating Systems: <strong>Ubuntu, macOS, and Windows</strong>
                <br></br>• Deployed websites on{" "}
                <strong>Netlify, Render, GitHub pages and Fly.io</strong>
              </p>
              <h2>Most Used:</h2>
              <br></br>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="skill-slider"
              >
                <div className="item">
                  <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg" />
                  <h5>React</h5>
                </div>
                <div className="item">
                  <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/rstudio/rstudio-original.svg" />
                  <h5>R</h5>
                </div>
                <div className="item">
                  <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg" />
                  <h5>PostgreSQL</h5>
                </div>
                <div className="item">
                  <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg" />
                  <h5>Python</h5>
                </div>
                <div className="item">
                  <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg" />
                  <h5>GitHub</h5>
                </div>
                <div className="item">
                  <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firebase/firebase-plain.svg" />
                  <h5>Firebase</h5>
                </div>
                <div className="item">
                  <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg" />

                  <h5>Figma</h5>
                </div>
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>
      <img className="background-image-left" src={colorSharp} alt="color" />
    </section>
  );


}